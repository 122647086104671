import "../styles/index.scss";

require("leaflet");

if (process.env.NODE_ENV === "development") {
  require("../index.html");
}
let ageConsent = document.getElementById("age-consent");
document.addEventListener(
  "click",
  function (event) {
    if (!event.target.matches("#age-yes")) return;
    event.preventDefault();
    ageConsent.style.opacity = "0";
    setTimeout(() => {
      ageConsent.remove();
    }, 300);
    document.cookie = "swiezeage=true;";
  },
  false
);

if (getCookie("swiezeage") == "true") {
  ageConsent.remove();
}

let map = L.map("map");
L.tileLayer("https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png", {
  attribution:
    'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.',
}).addTo(map);

let greenIcon = L.icon({
  iconUrl: "/public/images/marker.png",
  shadowUrl: "/public/images/shadow.png",
  iconSize: [0.7 * 60, 0.7 * 294],
  shadowSize: [0.7 * 80, 0.7 * 314],
  iconAnchor: [0.7 * 30, 0.7 * 294],
  shadowAnchor: [0.7 * 32, 0.7 * 300],
  popupAnchor: [0.7 * -3, 0.7 * -76],
});

let marker1 = L.marker([50.067425, 19.914731], { icon: greenIcon });
let marker2 = L.marker([50.12025, 19.61313], { icon: greenIcon });
let marker3 = L.marker([50.05055, 19.95136], { icon: greenIcon });
let marker4 = L.marker([50.0497, 19.95931], { icon: greenIcon });

let marker5 = L.marker([51.09435, 16.98156], { icon: greenIcon });
let marker6 = L.marker([51.04916, 17.058], { icon: greenIcon });
let marker7 = L.marker([51.11725, 17.03387], { icon: greenIcon });

//Warszawa
let marker8 = L.marker([52.1752579, 20.9955431], { icon: greenIcon });
let marker9 = L.marker([52.2563404, 20.9660374], { icon: greenIcon });
let marker10 = L.marker([52.2342766, 21.0331373], { icon: greenIcon });
let marker11 = L.marker([52.2129697, 21.0609932], { icon: greenIcon });
let marker12 = L.marker([52.2234977, 20.95474601], { icon: greenIcon });
let marker13 = L.marker([52.2528028, 21.081811], { icon: greenIcon });
let marker14 = L.marker([52.2014254, 21.0239788], { icon: greenIcon });
let marker15 = L.marker([52.2224865, 21.0115377], { icon: greenIcon });
let marker16 = L.marker([52.1847854, 21.0112547], { icon: greenIcon });

let group = L.featureGroup([
  marker1,
  marker2,
  marker3,
  marker4,
  marker5,
  marker6,
  marker7,
  marker8,
  marker9,
  marker10,
  marker11,
  marker12,
  marker13,
  marker14,
  marker15,
  marker16,
]).addTo(map);
console.log(group.getBounds());
map.fitBounds(group.getBounds());

function getCookie(name) {
  let value = "; " + document.cookie;
  let parts = value.split(`; ${name}=`);
  if (parts.length == 2) return parts.pop().split(";").shift();
}
